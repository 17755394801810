import {useState, useEffect} from 'react'
import Header from './components/Header'
import bar from '../src/assets/Bar.svg'
import html2pdf from "html2pdf.js";
import Table from './components/Table'

function App() {
  const [loan, setLoan] = useState('')
  const [balance, setBalance] = useState(0)
  const [minReqPayment, setMinReqPayment] = useState(0)
  const [state,setState] = useState([])
  
  const getPdf = () => {
    let opt = {
      margin: .10,
      filename: "Priority Payoff Guide.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 3},
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
    };
    let page = document.getElementById("app")
    html2pdf().set(opt).from(page).save()
  }

  useEffect(() => {
    const loanInput = document.querySelector("[name='loanType']")
    const balanceDueInput = document.querySelector("[name='balanceDue']")
    const minReqPaymentInput = document.querySelector("[name='minReqPayment']")
    
    loanInput.value = ""
    balanceDueInput.value = ""
    minReqPaymentInput.value = ""
    return () => {
      
    }
  }, [state])

  
  const addLoan = () => {
    let newLoan = {
      'loan': loan,
      'balance': balance,
      'minReqPayment': minReqPayment,
      'index': Math.floor(balance / minReqPayment),
    }
    
    setState([...state, newLoan ])
  }

  return (
    <div className="App" id="app">


      <Header/>
      <div className="main" id="main">
       <img id="payoff-bar" src={bar} alt="Wealth Without Wall Street"/>
      <div className="form" data-html2canvas-ignore="true" >
        <div className="field">
          <label htmlFor="loanType">Type of Loan</label>
          <input name="loanType" type="text" placeholder="ex. Mortgage" onChange={(e)=>{
            setLoan(e.target.value)
          }}/>
        </div>
        <div className="field">
          <label htmlFor="balanceDue">Balance Due</label>
          <input name="balanceDue" type="number" onChange={(e)=>{
            setBalance(e.target.value)
          }}/>
        </div>
        <div className="field">
          <label htmlFor="minReqPayment">Minimum Required Payment</label>
          <input name="minReqPayment" type="number" onChange={(e)=>{
            setMinReqPayment(e.target.value)
          }}/>
        </div>
        <div className="buttons">
          <button onClick={addLoan}>Add Loan</button>
          <button onClick={getPdf}>Download PDF</button> 
        </div>
      </div>
   <Table state={state}/>
      
      <div className="footer">
        <div className="info">One method to enhance your CASH FLOW is to "think" about how each loan is structured. The Priority Payoff Guide is a thinking tool to help identify which loans are efficient and which loans are not efficient.</div>
        <div className="note">Note: Inspired by the book, What Would the Rockefellors Do? by Garrett Gunderson</div>
      </div>
      </div>
    </div>
  );
}



export default App;
