import React from 'react';
import * as _ from 'lodash'

const Table = ({state}) => {

  const sorted =  _.sortBy(state,'index')

  return(
  <table id="table">
        <thead>
          <tr>
            <th width="30%" >Type of Loan</th>
            <th width="30%" >Balance</th>
            <th width="30%" >Min. Required Payment</th>
            <th width="10%" >Index</th>
          </tr>
        </thead>
        <tbody>
          {sorted.map((item, index) => (
            <tr key={index}>
              <td>{item.loan}</td>
              <td>${item.balance}</td>
              <td>${item.minReqPayment}</td>
              <td>{Math.floor(item.balance / item.minReqPayment)}</td>
            </tr>
          ))}
        </tbody>
      </table>
)};

export default Table;
